import React, { useState, useEffect } from 'react';
import { EDMDocumentViewer } from '@usb-edm/react-document-viewer';
import { EDMSideModal } from '@usb-edm/react-side-modal';
import { getQS, saveAsFile , previewFile} from '../../components/utilities/Utils';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    Redirect
  } from 'react-router-dom';
import './DocumentPreview.scss';
import callApi from "../../components/utilities/callApi";
import { endpoints } from '../../../config/connections';
import Loader from '../../components/utilities/Loader';

const DocumentViewerPage = (props) => {
    
  const [locationData, setLocationData] = useState(null);
  const [fileNameData, setFileNameData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let id = getQS('url');
    let requirementId = getQS('req')
    let type = getQS('type');
    setLoading(true)
    if (type ==='IB') {
      const apiURL = `${endpoints.api}/document-management/file-download/v1/inbox/${requirementId}/files/${id}`;
      callApi
        .getApiData(apiURL)
        .then((result) => {
          const fileurl = previewFile(
          result.data.binary,
          result.data.fileName
          );
          var values = result.data.fileName.split('.')[0];
          setFileNameData(values)
          if(fileurl) {
            setLocationData(fileurl);
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false);
          
        })
    }else if(type ==='CB'){
      
      const apiURL = `${endpoints.api}/document-management/closing-binders/v1/closingbinders/preview`;
      callApi
        .getApiDataCBpreview(apiURL, id)
        .then((result) => {
          console.log("result Daaaata",result);
          const fileurl = previewFile(
           result.data.binary,
          result.data.fileName
          );
          // console.log("respomseeeee",JSON.parse(result.data[0].binaryContent).fileName);
          setFileNameData(result.data.fileName.split('.')[0])
          // setBinaryData(JSON.parse(result.data[0].binaryContent).binary)
          if(fileurl) {
            setLocationData(fileurl);
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false);
          
        });
    } else {
      const apiURL = `${endpoints.api}/document-management/file-download/v1/files?fileIdList=${id}`;
      callApi
        .getApiData(apiURL)
        .then((result) => {
         
          const fileurl = previewFile(
            JSON.parse(result.data[0].binaryContent).binary,
            JSON.parse(result.data[0].binaryContent).fileName
          );
          // console.log("respomseeeee",JSON.parse(result.data[0].binaryContent).fileName);
          setFileNameData(JSON.parse(result.data[0].binaryContent).fileName.split('.')[0])
          // setBinaryData(JSON.parse(result.data[0].binaryContent).binary)
          if(fileurl) {
            setLocationData(fileurl);
          }
          setLoading(false)
        })
        .catch(() => {
          // console.log('error', error)
          setLoading(false);
          
        });
    }

  },[])
 
  const [file, setFile] = useState('');
  const [docLoadError, setDocLoadError] = useState(false);
  const handleClick=()=>{
    console.log("back button pressed.")
  } 
  const handleStatusUpdate = (selectedValue) => {
    const file = locationData;
    
  };

  const toolbar = {
    print: true,
    open: true,
    download: true,
    delete: false,
    rotate: true,
    zoomInOut: true,
    zoomSelect: false,
  };

  return (
    <div className='tests'>
     { !loading && locationData ? (
            <EDMDocumentViewer
            file={locationData}
            documentOptions={[
              { document: locationData, value: fileNameData },
            ]}
            handleDocChange={handleStatusUpdate}
            toolbar={toolbar}
            documentView="single"
            setDocLoadError={setDocLoadError}
            docLoadError={docLoadError}
            handleClick={handleClick}
            ></EDMDocumentViewer>
     ) : <Loader />}
     
    </div>
  );
};

export default DocumentViewerPage;
